<template>
  <v-app>
    <v-app-bar
      app
      :color="bgColor"
      dark
      elevate-on-scroll
      v-if="!['politicaprivacidad'].includes($route.name)"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-md-and-up">
        <svg-icon type="mdi" :path="path"></svg-icon>
      </v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="Gremti Logo"
          contain
          src="./assets/logo_white.svg"
          transition="scale-transition"
          :width="$vuetify.breakpoint.mdAndUp ? 130 : 100"
        />
      </div>

      <v-spacer></v-spacer>

      <div class="hidden-sm-and-down">
        <v-btn v-scroll-to="'#home'" text small class="font-weight-bold">
          Inicio
        </v-btn>
        <v-btn v-scroll-to="'#us'" text small class="font-weight-bold">
          Nosotros
        </v-btn>
        <v-btn
          v-scroll-to="'#our-services'"
          text
          small
          class="font-weight-bold"
        >
          Nuestros productos
        </v-btn>

        <v-btn v-scroll-to="'#our-value'" text small class="font-weight-bold">
          Soporte Empresarial Tidingo
        </v-btn>
        <v-btn v-scroll-to="'#our-clients'" text small class="font-weight-bold">
          Clientes
        </v-btn>
        <v-btn v-scroll-to="'#avisos'" text small class="font-weight-bold">
          Avisos de privacidad
        </v-btn>
        <v-btn v-scroll-to="'#contact-us'" text small class="font-weight-bold">
          Contáctanos
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary app>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="primary lighten-2">
          <v-list-item @click="drawer = false" v-scroll-to="'#home'">
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item>

          <v-list-item @click="drawer = false" v-scroll-to="'#us'">
            <v-list-item-title> Nosotros</v-list-item-title>
          </v-list-item>

          <v-list-item @click="drawer = false" v-scroll-to="'#our-services'">
            <v-list-item-title>Nuestros productos</v-list-item-title>
          </v-list-item>
          <v-list-item @click="drawer = false" v-scroll-to="'#our-clients'">
            <v-list-item-title>Clientes</v-list-item-title>
          </v-list-item>
          <v-list-item @click="drawer = false" v-scroll-to="'#contact-us'">
            <v-list-item-title>Contáctanos</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-list-item @click="drawer = false">
          <v-list-item-title>Cerrar menu</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-btn
      id="social_media_whatsapp"
      v-if="$vuetify.breakpoint.mdAndUp"
      fab
      color="#00bb2d"
      style="
        z-index: 6;
        cursor: pointer;
        position: fixed;
        bottom: 300px;
        right: 20px;
        height: 60px;
        width: 60px;
      "
      href="https://wa.me/523328326870/?text=Hola%Gremti"
      target="_blank"
    >
      <v-icon color="white">mdi-whatsapp</v-icon>
    </v-btn>
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp"
      fab
      style="
        z-index: 6;
        cursor: pointer;
        background-color: #d6249f;
        position: fixed;
        bottom: 230px;
        right: 20px;
        height: 60px;
        width: 60px;
      "
      href="https://www.instagram.com/tidingomx/"
      target="_blank"
    >
      <v-icon color="white">mdi-instagram</v-icon>
    </v-btn>
    <v-btn
      id="social_media_facebook"
      v-if="$vuetify.breakpoint.mdAndUp"
      fab
      color="#3b5998"
      style="
        z-index: 6;
        cursor: pointer;
        position: fixed;
        bottom: 160px;
        right: 20px;
        height: 60px;
        width: 60px;
      "
      href="https://www.facebook.com/grupoempresarialtidingo"
      target="_blank"
    >
      <v-icon color="white">mdi-facebook</v-icon>
    </v-btn>
    <v-btn
      id="social_media_facebook"
      v-if="$vuetify.breakpoint.mdAndUp"
      fab
      color="#D6483C"
      style="
        z-index: 6;
        cursor: pointer;
        position: fixed;
        bottom: 90px;
        right: 20px;
        height: 60px;
        width: 60px;
      "
      href="mailto:soporte@gremti.com"
      target="_blank"
    >
      <v-icon color="white">mdi mdi-email</v-icon>
    </v-btn>
    <!--RESPONSIVE-->
    <v-speed-dial
      v-model="fab"
      :top="top"
      :bottom="bottom"
      :right="right"
      :left="left"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
      v-if="$vuetify.breakpoint.smAndDown"
      style="
        z-index: 6;
        cursor: pointer;
        position: fixed;
        bottom: 90px;
        right: 20px;
        height: 60px;
        width: 60px;
      "
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-share-variant</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="red"
        target="_blank"
        href="mailto:soporte@gremti.com"
      >
        <v-icon>mdi-email</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        target="_blank"
        id="social_media_facebook_movil"
        href="https://www.facebook.com/grupoempresarialtidingo"
        color="#3b5998"
      >
        <v-icon>mdi-facebook</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        target="_blank"
        href="https://www.instagram.com/tidingomx/"
        color="#d6249f"
      >
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        id="social_media_whatsapp_movil"
        color="#00bb2d"
        href="https://wa.me/523328326870/?text=Hola%20Gremti"
        target="_blank"
      >
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-main class="pt-0">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<style>
.icon-size {
  height: 20px;
}

.icon-size-value {
  height: 40px;
}
</style>
<script>
import Home from "./views/Home";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMenu } from "@mdi/js";

export default {
  name: "App",

  components: {
    Home,
    SvgIcon,
  },

  data: () => ({
    //
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    offsetTop: 0,
    drawer: false,
    group: 1,
    path: mdiMenu,
  }),
  methods: {
    onScroll(e) {
      this.offsetTop = window.scrollY;
    },
  },
  computed: {
    bgColor() {
      if (this.offsetTop > 10) {
        return "secondary";
      } else {
        return "transparent";
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
