<template>
  <div id="home">
    <HeaderImage />
    <v-container fluid id="us" class="pa-0">
      <v-row class="primary pt-4 mx-0">
        <v-col
          cols="12"
          sm="12"
          md="8"
          offset-md="2"
          class="d-flex justify-center align-center flex-column py-12"
        >
          <h3 class="secondary--text text-center">
            Nuestra conversación esta enfocada en la verdad, somos justos entre
            nosotros, socios, proveedores, clientes e inversionistas nunca
            anteponemos nuestras necesidades o intereses; entendemos que somos
            personas con sueños, por lo cual actuamos con honradez.
          </h3>
          <svg-icon
            class="secondary--text mt-4"
            type="mdi"
            :path="mdiChevronDoubleDown"
          ></svg-icon>
        </v-col>
      </v-row>

      <our-mission></our-mission>
      <our-values></our-values>
      <our-products></our-products>
      <our-products-gob></our-products-gob>
      <our-value></our-value>
      <our-clients></our-clients>
      <avisos></avisos>
      <contact></contact>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronDoubleDown } from "@mdi/js";
const HeaderImage = () =>
  import(/* webpackChunkName: "headerimage" */ "../components/HeaderImage.vue");
const OurProducts = () =>
  import(/* webpackChunkName: "OurProducts" */ "../components/OurProducts.vue");
const OurProductsGob = () =>
  import(
    /* webpackChunkName: "OurProductsGob" */ "../components/OurProductsGob.vue"
  );
const OurValue = () =>
  import(/* webpackChunkName: "OurValue" */ "../components/OurValue.vue");
const Steps = () =>
  import(/* webpackChunkName: "Steps" */ "../components/Steps.vue");
const Avisos = () =>
  import(/* webpackChunkName: "Avisos" */ "../components/Avisos.vue");
const Contact = () =>
  import(/* webpackChunkName: "Contact" */ "../components/Contact.vue");
const OurClients = () =>
  import(/* webpackChunkName: "OurClients" */ "../components/OurClients.vue");
const OurMission = () =>
  import(/* webpackChunkName: "OurMission" */ "../components/OurMission.vue");
const OurValues = () =>
  import(/* webpackChunkName: "OurValues" */ "../components/OurValues.vue");

export default {
  name: "Home",
  components: {
    HeaderImage,
    OurProducts,
    Steps,
    OurValue,
    Contact,
    OurClients,
    Avisos,
    SvgIcon,
    OurMission,
    OurValues,
    OurProductsGob,
  },
  data: () => ({
    mdiChevronDoubleDown: mdiChevronDoubleDown,
  }),
};
</script>
