import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icon: "mdi",
  theme: {
    themes: {
      light: {
        primary: "#04E3BB",
        secondary: "#192A67",
        accent: "#2962FF",
        error: "#F30D46",
      },
    },
  },
});
