import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: function() {
      return import(/* webpackChunkName: "home" */ "../views/Home.vue");
    },
  },
  {
    path: "/politica-de-privacidad",
    name: "politica",
    component: function() {
      return import(
        /* webpackChunkName: "aviso" */ "../views/AvisoDePrivacidad.vue"
      );
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
